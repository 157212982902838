import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 293.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,293.000000) scale(0.100000,-0.100000)">


<path d="M1795 2696 c-33 -13 -150 -67 -260 -121 -1017 -496 -993 -485 -1051
-485 -97 0 -180 -57 -213 -145 -21 -55 -21 -72 -21 -794 l0 -738 30 -59 c33
-65 79 -104 145 -123 30 -9 296 -11 1020 -9 l980 3 42 22 c53 29 98 84 112
139 6 23 11 129 11 233 l0 191 55 0 c46 0 60 4 80 25 l25 24 0 298 c-1 218 -4
302 -13 314 -21 27 -57 41 -100 37 l-42 -3 -5 215 c-5 236 -8 246 -72 307 -41
40 -95 63 -148 63 -35 0 -42 4 -51 28 -6 15 -25 61 -44 102 -18 41 -63 143
-99 226 -37 83 -81 167 -97 187 -64 78 -182 104 -284 63z m198 -26 c15 -7 27
-17 27 -21 0 -4 9 -11 20 -14 11 -4 18 -10 15 -15 -3 -4 2 -11 11 -14 13 -5
35 -48 94 -181 5 -11 27 -59 49 -106 21 -48 48 -110 58 -139 27 -77 58 -107
117 -114 49 -5 96 -28 96 -46 0 -6 5 -10 11 -10 11 0 43 -29 45 -39 0 -3 7
-22 16 -41 12 -27 16 -79 17 -225 2 -218 5 -225 78 -225 23 0 43 -5 43 -11 0
-5 5 -7 10 -4 25 15 31 -57 28 -323 l-3 -274 -24 -19 c-14 -12 -39 -19 -65
-19 -23 0 -47 -5 -54 -12 -8 -8 -12 -64 -12 -184 0 -217 -10 -262 -67 -321
-24 -24 -57 -46 -73 -50 -17 -3 -32 -9 -35 -13 -2 -4 -444 -6 -980 -4 l-976 3
-46 24 c-26 13 -60 43 -77 66 l-31 43 -3 761 c-2 581 1 769 10 792 15 38 64
92 101 111 15 8 63 19 105 24 59 7 95 18 154 50 43 22 84 40 92 40 8 0 16 4
18 9 4 11 210 108 232 109 5 0 12 4 15 9 3 4 33 21 66 37 33 16 173 85 311
153 138 67 258 123 267 123 10 0 17 5 17 11 0 6 7 8 17 5 10 -4 14 -4 11 0
-10 9 108 64 157 73 44 8 96 1 138 -19z"/>
<path d="M1815 2558 c-16 -6 -42 -19 -56 -29 -14 -10 -38 -21 -55 -24 -16 -4
-48 -15 -71 -26 -24 -10 -47 -19 -52 -19 -5 0 -11 -3 -13 -7 -6 -15 -179 -93
-191 -87 -7 4 -9 4 -5 -1 4 -4 -34 -27 -84 -51 -50 -23 -96 -48 -101 -53 -6
-6 -15 -11 -20 -11 -18 0 -234 -109 -247 -125 -7 -8 -10 -15 -7 -15 10 0 213
97 685 327 62 30 115 53 118 50 2 -2 0 -16 -6 -31 -12 -33 -13 -86 -1 -105 5
-8 29 -18 53 -22 38 -7 37 -8 -16 -4 -56 4 -60 2 -100 -35 l-41 -39 0 -74 c0
-42 4 -79 8 -84 5 -4 61 -8 125 -9 108 -1 110 0 19 3 l-98 4 -15 34 c-8 19
-14 43 -14 54 0 11 -5 21 -12 23 -20 7 24 71 62 90 39 20 110 23 143 7 21 -10
21 -10 3 5 -26 21 -11 20 25 -1 31 -18 35 -28 19 -38 -5 -3 -10 -16 -10 -28 1
-18 4 -16 20 14 10 19 26 41 34 48 21 18 128 26 154 12 14 -7 21 -8 24 0 2 6
16 1 32 -12 33 -26 64 -91 47 -97 -6 -2 -11 -12 -11 -23 0 -11 -6 -35 -14 -54
l-15 -34 -103 -4 -103 -3 105 -2 c58 -1 110 2 116 6 21 14 44 76 44 117 0 58
-54 127 -110 141 -33 9 -40 21 -40 70 0 30 -8 53 -31 83 -46 64 -118 86 -194
59z m139 -27 c36 -24 66 -69 66 -99 0 -13 -3 -21 -8 -18 -4 2 -8 -10 -9 -27 0
-17 -6 -33 -12 -35 -6 -2 -32 -15 -57 -28 -35 -19 -50 -22 -65 -14 -27 15 -24
28 5 23 43 -9 66 13 66 62 0 69 -5 75 -64 75 -29 0 -57 -5 -64 -12 -14 -14
-16 -94 -2 -103 6 -4 10 13 10 43 0 54 8 62 59 62 35 0 51 -17 51 -54 0 -46
-10 -54 -70 -56 -119 -5 -117 -6 -129 50 -9 42 -8 56 4 80 23 45 40 51 180 69
6 0 23 -8 39 -18z"/>
<path d="M1900 2440 c0 -7 -7 -9 -16 -6 -9 4 -23 1 -30 -5 -19 -16 -18 -48 2
-56 23 -8 49 11 47 33 -1 10 3 22 9 25 7 4 7 9 -1 13 -6 4 -11 2 -11 -4z m-10
-34 c0 -21 -21 -31 -30 -15 -5 7 -6 17 -4 21 9 14 34 9 34 -6z"/>
<path d="M2007 2253 c-4 -3 -7 -14 -7 -24 0 -9 -6 -19 -12 -22 -10 -4 -10 -7
0 -18 6 -7 12 -28 12 -46 0 -23 5 -33 15 -33 11 0 15 11 15 39 0 22 5 42 13
44 9 4 8 8 -3 16 -13 10 -13 13 3 22 9 5 17 14 17 19 0 11 -43 14 -53 3z"/>
<path d="M1783 2233 c-7 -2 -13 -11 -13 -19 0 -18 -11 -18 -36 1 -10 8 -22 15
-26 15 -16 0 -7 -47 12 -68 l21 -22 -21 0 c-11 0 -20 -4 -20 -10 0 -5 15 -10
34 -10 41 0 86 33 86 64 0 12 5 27 12 34 8 8 9 12 1 12 -6 0 -17 2 -25 4 -7 3
-19 2 -25 -1z"/>
<path d="M1855 2200 c-8 -14 3 -81 16 -100 8 -12 12 -12 16 -2 4 10 8 9 19 -5
7 -10 14 -13 14 -7 0 5 -7 19 -15 30 -8 10 -15 36 -15 57 0 36 -20 51 -35 27z"/>
<path d="M945 2080 c45 -11 627 -11 645 0 10 6 -104 9 -330 9 -190 -1 -331 -5
-315 -9z"/>
<path d="M1065 2020 c-546 -5 -550 -5 -592 -27 -23 -13 -45 -27 -48 -33 -4 -6
-11 -8 -16 -5 -5 4 -9 -1 -9 -9 0 -9 -4 -16 -8 -16 -5 0 -20 -21 -33 -47 l-24
-47 0 -680 0 -681 22 -45 c13 -25 23 -41 23 -37 0 4 14 -8 31 -28 17 -19 36
-35 42 -35 6 0 19 -8 30 -18 18 -16 76 -17 931 -16 l911 0 55 34 c73 45 81 54
103 114 16 41 20 82 22 209 2 86 -1 157 -5 157 -5 0 -10 -74 -12 -165 -2 -122
-6 -167 -17 -177 -11 -11 -12 -9 -6 10 5 16 2 22 -9 22 -19 0 -22 -46 -3 -53
23 -8 -27 -62 -62 -65 -26 -3 -31 -7 -26 -22 12 -38 -22 -40 -935 -40 -483 0
-892 3 -909 6 -40 8 -116 75 -141 125 -19 37 -20 63 -20 703 0 432 4 674 10
692 18 47 67 99 117 124 l48 25 885 -1 c871 0 886 -1 931 -21 51 -23 102 -71
94 -90 -2 -7 0 -13 6 -13 26 0 39 -71 40 -217 1 -106 4 -137 10 -108 4 22 6
99 4 172 -5 142 -9 154 -72 226 -18 21 -37 37 -43 37 -5 0 -10 4 -10 8 0 34
-300 41 -1315 32z"/>
<path d="M1703 1904 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
-16z"/>
<path d="M1784 1905 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
8z"/>
<path d="M1867 1914 c-4 -4 -7 -16 -7 -26 0 -13 7 -18 26 -18 21 0 25 4 22 22
-3 23 -27 35 -41 22z"/>
<path d="M1944 1905 c-8 -20 4 -35 27 -35 14 0 19 7 19 25 0 18 -5 25 -20 25
-11 0 -23 -7 -26 -15z"/>
<path d="M2030 1895 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2110 1895 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2190 1895 c0 -21 5 -25 29 -25 25 0 28 3 23 25 -3 18 -11 25 -28 25
-19 0 -24 -5 -24 -25z"/>
<path d="M2274 1895 c-2 -21 2 -25 27 -25 24 0 29 4 29 25 0 21 -5 25 -27 25
-23 0 -28 -5 -29 -25z"/>
<path d="M2360 1895 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1370 1895 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M1450 1895 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M1530 1895 c0 -11 7 -15 23 -13 12 2 22 8 22 13 0 6 -10 11 -22 13
-16 2 -23 -2 -23 -13z"/>
<path d="M1620 1895 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M2360 1778 c0 -23 4 -28 26 -28 22 0 25 4 22 27 -2 19 -9 27 -25 28
-19 1 -23 -3 -23 -27z"/>
<path d="M2446 1803 c-3 -4 -6 -17 -6 -30 0 -16 6 -23 19 -23 14 0 19 7 17 25
-1 24 -18 40 -30 28z"/>
<path d="M1548 1793 c-27 -7 -22 -33 6 -33 19 0 23 4 19 20 -3 11 -6 19 -7 19
0 -1 -9 -3 -18 -6z"/>
<path d="M1620 1781 c0 -15 6 -21 21 -21 14 0 19 5 17 17 -5 26 -38 29 -38 4z"/>
<path d="M1700 1780 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M1780 1781 c0 -16 6 -21 26 -21 33 0 30 33 -4 38 -17 3 -22 -2 -22
-17z"/>
<path d="M1863 1784 c-3 -8 -1 -20 5 -26 16 -16 42 -2 42 22 0 24 -38 28 -47
4z"/>
<path d="M1947 1793 c-9 -20 0 -38 21 -41 18 -3 22 1 22 22 0 19 -5 26 -19 26
-11 0 -22 -3 -24 -7z"/>
<path d="M2030 1774 c0 -21 4 -25 23 -22 14 2 23 11 25 26 3 18 -1 22 -22 22
-21 0 -26 -5 -26 -26z"/>
<path d="M2110 1775 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2190 1774 c0 -22 4 -25 27 -22 19 2 27 9 28 26 1 18 -3 22 -27 22
-23 0 -28 -4 -28 -26z"/>
<path d="M2273 1775 c0 -21 4 -25 28 -25 24 0 29 4 29 25 0 21 -5 25 -29 25
-24 0 -28 -4 -28 -25z"/>
<path d="M1370 1775 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M1450 1775 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M2196 1683 c-3 -4 -6 -17 -6 -30 0 -17 6 -23 23 -23 30 0 35 5 30 33
-4 21 -33 33 -47 20z"/>
<path d="M2277 1684 c-4 -4 -7 -18 -7 -31 0 -19 5 -23 31 -23 27 0 30 3 27 28
-3 26 -34 43 -51 26z"/>
<path d="M2360 1660 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M2440 1661 c0 -24 4 -31 19 -31 15 0 19 6 18 27 -2 17 -9 29 -20 31
-13 3 -17 -4 -17 -27z"/>
<path d="M1457 1674 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M1537 1673 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
<path d="M1620 1661 c0 -15 6 -21 21 -21 14 0 19 5 17 17 -5 26 -38 29 -38 4z"/>
<path d="M1700 1660 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M1780 1661 c0 -16 6 -21 24 -21 31 0 29 34 -2 38 -17 3 -22 -2 -22
-17z"/>
<path d="M1862 1658 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 18 -1 22 -23 22
-22 0 -26 -4 -23 -22z"/>
<path d="M1947 1661 c-6 -24 0 -31 25 -31 13 0 18 7 18 25 0 31 -35 35 -43 6z"/>
<path d="M2030 1654 c0 -21 4 -25 23 -22 14 2 23 11 25 26 3 18 -1 22 -22 22
-21 0 -26 -5 -26 -26z"/>
<path d="M2110 1655 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1370 1655 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M762 1522 c-82 -83 -187 -300 -228 -466 -33 -137 -32 -187 3 -226 49
-54 141 -50 178 7 9 14 22 52 29 86 l13 62 58 0 58 0 13 -62 c15 -74 35 -107
74 -123 31 -13 95 -7 105 10 3 5 -1 20 -9 32 -18 28 -56 142 -90 268 -14 52
-37 136 -51 187 -27 98 -32 191 -14 225 15 28 -10 48 -58 48 -27 0 -43 -10
-81 -48z m113 -43 c-5 -57 22 -207 45 -251 6 -9 13 -36 16 -60 4 -24 11 -48
16 -54 5 -7 8 -16 7 -20 -1 -5 0 -16 3 -24 3 -8 11 -35 18 -60 7 -25 19 -65
27 -90 34 -105 35 -96 -6 -98 -31 -2 -39 3 -57 31 -12 18 -25 48 -29 67 -17
80 -40 100 -116 100 -47 0 -66 -22 -79 -90 -6 -30 -17 -58 -25 -63 -8 -4 -15
-14 -15 -22 0 -17 -24 -25 -68 -23 -23 1 -31 5 -28 15 3 7 0 13 -6 13 -27 0
-46 32 -42 68 15 115 46 235 79 302 78 156 86 173 120 225 65 101 148 121 140
34z"/>
<path d="M799 1187 c-20 -40 -9 -67 26 -67 16 0 28 9 38 29 14 28 13 31 -5 48
-20 18 -21 18 -16 -19 3 -31 0 -39 -15 -44 -27 -9 -29 -2 -13 44 18 50 8 55
-15 9z"/>
<path d="M954 1558 c-50 -24 -57 -113 -20 -253 123 -462 139 -501 223 -512 29
-4 46 1 73 19 36 25 84 88 95 126 11 37 -12 25 -46 -25 -31 -45 -64 -81 -78
-84 -58 -13 -103 8 -127 60 -15 31 -42 119 -74 236 -4 17 -15 47 -24 68 -9 21
-14 41 -12 46 3 4 -6 46 -19 93 -37 137 -19 202 54 196 49 -4 75 -50 130 -233
12 -38 33 -83 46 -100 l24 -30 -13 45 c-58 191 -72 231 -96 281 -37 72 -81 94
-136 67z"/>
<path d="M1600 1541 c-32 -32 -30 -25 -60 -211 -12 -74 -24 -144 -27 -155 -5
-17 -4 -18 10 -7 22 18 44 100 58 216 9 75 17 104 34 124 25 29 77 35 101 11
31 -32 32 -70 4 -229 -37 -214 -89 -360 -148 -413 -10 -10 -42 -20 -69 -24
-44 -5 -53 -3 -74 19 -23 24 -38 44 -59 78 -13 21 -23 5 -16 -24 9 -38 62 -93
101 -106 64 -21 142 22 189 104 48 82 101 284 125 477 11 87 -2 130 -48 153
-47 25 -88 20 -121 -13z"/>
<path d="M2048 1563 c-10 -3 -18 -13 -18 -24 0 -26 36 -25 43 1 6 23 0 28 -25
23z"/>
<path d="M2117 1563 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 25 -18 20 0 25 5 25 25
0 18 -5 25 -18 25 -10 0 -22 -3 -25 -7z"/>
<path d="M2190 1561 c0 -23 14 -41 31 -41 14 0 19 7 19 25 0 20 -5 25 -25 25
-14 0 -25 -4 -25 -9z"/>
<path d="M2274 1554 c3 -9 6 -20 6 -25 0 -18 38 -9 43 10 6 25 0 31 -31 31
-18 0 -23 -4 -18 -16z"/>
<path d="M2360 1545 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2440 1551 c0 -11 5 -23 11 -26 13 -8 15 -6 23 23 6 18 3 22 -13 22
-14 0 -21 -6 -21 -19z"/>
<path d="M1290 1541 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z"/>
<path d="M1377 1554 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M1457 1554 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M1536 1552 c-8 -13 11 -35 23 -28 16 10 14 36 -3 36 -8 0 -17 -4 -20
-8z"/>
<path d="M1784 1544 c3 -9 6 -18 6 -20 0 -2 9 -4 21 -4 15 0 19 4 15 16 -3 9
-6 18 -6 20 0 2 -9 4 -21 4 -15 0 -19 -4 -15 -16z"/>
<path d="M1860 1541 c0 -24 13 -33 34 -25 27 10 20 44 -9 44 -18 0 -25 -5 -25
-19z"/>
<path d="M1945 1538 c1 -15 9 -24 23 -26 18 -3 22 2 22 22 0 21 -5 26 -23 26
-18 0 -23 -5 -22 -22z"/>
<path d="M2104 1499 c-62 -8 -146 -51 -192 -99 -36 -38 -92 -147 -92 -180 0
-14 -7 -20 -21 -20 -14 0 -19 -5 -17 -17 2 -10 10 -17 16 -15 7 1 12 -10 12
-25 0 -16 12 -59 26 -98 14 -38 27 -81 29 -95 1 -14 8 -24 15 -22 6 1 27 -11
46 -28 19 -16 52 -39 74 -50 27 -14 38 -25 34 -35 -4 -10 0 -15 14 -15 11 0
22 5 24 11 2 7 12 7 29 0 36 -13 349 -11 374 3 15 8 -30 12 -195 17 -195 5
-219 8 -255 27 -138 75 -221 250 -178 374 29 86 91 178 118 178 7 0 20 9 30
20 36 39 114 52 312 52 128 0 181 4 174 11 -12 12 -299 17 -377 6z"/>
<path d="M1455 1430 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
<path d="M1790 1420 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M1860 1420 c0 -24 35 -28 44 -4 9 23 8 24 -19 24 -18 0 -25 -5 -25
-20z"/>
<path d="M1283 1384 c-17 -9 -36 -28 -42 -42 -28 -72 -41 -166 -21 -146 5 5
19 36 30 69 11 32 30 69 42 82 23 24 52 30 63 13 4 -6 10 -8 14 -5 15 9 48
-40 71 -105 26 -73 50 -109 50 -74 0 25 -31 118 -55 163 -16 32 -66 61 -102
61 -10 0 -32 -7 -50 -16z"/>
<path d="M2081 1361 c-55 -22 -89 -54 -112 -106 -20 -44 -24 -121 -9 -175 11
-37 60 -100 79 -100 6 0 11 -4 11 -8 0 -25 93 -34 315 -34 205 1 231 3 242 19
9 12 13 69 13 204 0 157 -3 190 -16 203 -13 14 -49 16 -247 15 -199 0 -238 -3
-276 -18z m519 -207 l0 -194 -227 0 c-126 0 -245 5 -265 10 -104 29 -164 153
-125 255 16 42 36 67 75 96 33 24 38 24 280 29 136 3 250 4 255 2 4 -2 7 -91
7 -198z"/>
<path d="M2144 1229 c-28 -10 -54 -47 -54 -75 0 -31 30 -63 67 -70 66 -13 111
78 61 123 -30 26 -48 32 -74 22z m71 -45 c17 -37 13 -50 -23 -81 -26 -22 -91
18 -92 56 0 25 42 61 71 61 21 0 30 -8 44 -36z"/>
<path d="M1787 1306 c-7 -16 1 -26 20 -26 7 0 13 9 13 20 0 23 -25 27 -33 6z"/>
<path d="M1780 1066 c0 -18 16 -29 30 -21 13 8 1 35 -16 35 -8 0 -14 -6 -14
-14z"/>
<path d="M1707 963 c-15 -15 -7 -43 13 -43 15 0 20 7 20 25 0 24 -18 34 -33
18z"/>
<path d="M1780 950 c0 -23 26 -38 41 -23 14 14 0 43 -22 43 -12 0 -19 -7 -19
-20z"/>
<path d="M1627 844 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M1700 830 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M1782 828 c4 -30 43 -33 43 -3 0 13 -8 21 -23 23 -20 3 -23 0 -20
-20z"/>
<path d="M1862 828 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 18 -1 22 -23 22
-22 0 -26 -4 -23 -22z"/>
<path d="M1947 831 c-6 -24 0 -31 25 -31 23 0 25 35 2 44 -21 8 -21 8 -27 -13z"/>
<path d="M616 758 c-2 -7 -9 -28 -15 -47 -14 -42 -14 -41 3 -41 8 0 16 6 19
13 3 9 9 9 27 -1 31 -18 34 -10 18 40 -14 42 -44 63 -52 36z"/>
<path d="M690 719 c0 -66 24 -62 28 4 2 37 0 47 -12 47 -12 0 -16 -12 -16 -51z"/>
<path d="M740 721 c0 -28 5 -51 10 -51 6 0 10 7 10 15 0 8 7 15 15 15 8 0 15
-7 15 -15 0 -8 4 -15 10 -15 5 0 8 21 7 48 -2 45 -3 47 -34 50 l-33 3 0 -50z"/>
<path d="M820 761 c0 -5 7 -12 16 -15 10 -4 14 -16 12 -37 -2 -19 2 -34 10
-36 8 -3 12 8 12 36 0 23 5 41 10 41 6 0 10 5 10 10 0 6 -16 10 -35 10 -19 0
-35 -4 -35 -9z"/>
<path d="M909 735 c-1 -19 -2 -40 -3 -47 -1 -6 5 -13 12 -15 8 -3 12 10 12 46
0 57 -19 71 -21 16z"/>
<path d="M950 720 c0 -27 5 -50 10 -50 6 0 10 14 10 32 0 34 15 29 22 -7 4
-24 22 -23 26 2 7 34 22 38 22 5 0 -18 5 -32 10 -32 6 0 10 23 10 50 0 42 -3
50 -19 50 -13 0 -21 -10 -26 -32 l-7 -33 -14 33 c-22 51 -44 42 -44 -18z"/>
<path d="M1080 719 l0 -50 33 3 c32 3 41 23 10 23 -9 0 -18 6 -21 14 -3 9 2
11 17 6 12 -4 21 -2 21 4 0 6 -9 11 -20 11 -11 0 -20 5 -20 10 0 6 9 10 20 10
11 0 20 5 20 10 0 6 -13 10 -30 10 -29 0 -30 -1 -30 -51z"/>
<path d="M1166 723 c8 -56 8 -57 29 -50 8 4 15 19 16 34 l1 28 10 -32 c11 -33
35 -44 43 -20 2 6 6 29 10 51 5 29 3 37 -7 34 -7 -3 -15 -18 -18 -34 l-6 -29
-7 33 c-3 17 -11 32 -16 32 -6 0 -15 -15 -20 -32 l-9 -33 -7 33 c-12 50 -27
38 -19 -15z"/>
<path d="M1301 725 c-16 -53 -12 -62 19 -43 18 10 24 10 27 1 3 -7 9 -13 14
-13 12 0 11 16 -2 63 -14 53 -41 50 -58 -8z"/>
<path d="M1388 738 c-8 -51 1 -68 33 -68 19 0 29 5 29 15 0 8 -9 15 -20 15
-16 0 -20 7 -20 35 0 44 -16 46 -22 3z"/>
<path d="M1460 733 c0 -56 8 -61 83 -62 52 0 67 3 67 14 0 9 -11 16 -27 16
-24 2 -25 2 -5 6 28 5 29 23 1 23 -11 0 -17 5 -14 10 3 6 15 10 26 10 10 0 19
5 19 10 0 6 -16 10 -35 10 -34 0 -35 -1 -35 -41 0 -33 -3 -40 -16 -35 -9 3
-20 6 -25 6 -5 0 -9 16 -9 35 0 24 -5 35 -15 35 -11 0 -15 -11 -15 -37z"/>
<path d="M1620 761 c0 -5 7 -12 16 -15 15 -6 15 -8 0 -25 -15 -16 -15 -19 2
-34 27 -24 32 -21 32 17 0 21 6 36 16 40 8 3 12 10 9 16 -8 12 -75 13 -75 1z"/>
<path d="M2293 732 c-17 -2 -23 -10 -23 -28 0 -20 5 -24 30 -24 25 0 30 4 30
24 0 29 -6 33 -37 28z"/>
<path d="M2360 710 c0 -26 4 -30 26 -30 22 0 25 4 22 27 -2 18 -10 27 -25 29
-20 3 -23 -1 -23 -26z"/>
<path d="M2446 733 c-3 -4 -6 -17 -6 -30 0 -16 6 -23 20 -23 14 0 20 7 20 24
0 23 -22 41 -34 29z"/>
<path d="M1707 723 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
<path d="M1780 711 c0 -16 6 -21 26 -21 33 0 30 33 -4 38 -17 3 -22 -2 -22
-17z"/>
<path d="M1860 712 c0 -25 7 -31 31 -25 29 8 25 43 -6 43 -18 0 -25 -5 -25
-18z"/>
<path d="M1947 711 c-6 -24 0 -31 25 -31 13 0 18 7 18 25 0 31 -35 35 -43 6z"/>
<path d="M2030 704 c0 -21 4 -25 23 -22 14 2 23 11 25 26 3 18 -1 22 -22 22
-21 0 -26 -5 -26 -26z"/>
<path d="M2110 705 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2190 704 c0 -22 4 -25 27 -22 19 2 27 9 28 26 1 18 -3 22 -27 22
-23 0 -28 -4 -28 -26z"/>
<path d="M2195 612 c-3 -3 -5 -16 -5 -29 0 -21 4 -24 27 -21 22 2 28 8 27 28
0 19 -5 25 -21 26 -13 1 -25 -1 -28 -4z"/>
<path d="M2279 618 c0 -2 -3 -15 -5 -30 -4 -26 -2 -28 26 -28 28 0 31 3 28 28
-2 19 -9 28 -25 30 -13 2 -23 2 -24 0z"/>
<path d="M2360 590 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M2440 590 c0 -25 4 -31 18 -28 11 2 17 12 17 28 0 16 -6 26 -17 28
-14 3 -18 -3 -18 -28z"/>
<path d="M1374 599 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"/>
<path d="M1455 601 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
<path d="M1535 600 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"/>
<path d="M1620 590 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M1700 590 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M1780 590 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
-18 3 -23 -2 -23 -18z"/>
<path d="M1860 590 c0 -15 7 -20 25 -20 18 0 25 5 25 20 0 15 -7 20 -25 20
-18 0 -25 -5 -25 -20z"/>
<path d="M1944 594 c3 -9 6 -18 6 -20 0 -2 9 -4 20 -4 13 0 20 7 20 20 0 15
-7 20 -26 20 -20 0 -25 -4 -20 -16z"/>
<path d="M2030 592 c0 -25 7 -31 31 -25 29 8 25 43 -6 43 -18 0 -25 -5 -25
-18z"/>
<path d="M2112 588 c2 -15 11 -24 26 -26 18 -3 22 1 22 22 0 21 -5 26 -26 26
-21 0 -25 -4 -22 -22z"/>
<path d="M721 470 c-1 -26 2 -31 9 -20 8 12 12 12 18 2 6 -8 9 -2 9 17 0 23
-3 28 -13 20 -8 -7 -14 -6 -17 3 -3 7 -6 -3 -6 -22z"/>
<path d="M770 470 c0 -27 2 -29 43 -28 23 0 54 6 69 14 24 12 27 12 31 -4 3
-9 5 -3 6 14 1 28 -2 31 -26 30 -21 -1 -29 -7 -31 -23 -2 -14 -10 -23 -20 -22
-11 0 -12 3 -4 6 6 2 12 9 12 14 0 6 -4 7 -10 4 -5 -3 -10 -2 -10 4 0 5 6 11
13 14 6 2 -7 5 -30 5 -41 1 -43 -1 -43 -28z m43 14 c10 -10 -5 -34 -22 -34
-14 0 -14 2 -1 10 10 6 11 10 3 10 -7 0 -13 5 -13 10 0 11 23 14 33 4z"/>
<path d="M930 469 c0 -26 3 -30 10 -19 9 13 11 13 20 0 7 -11 10 -11 10 -2 0
7 -9 22 -20 34 l-20 22 0 -35z"/>
<path d="M984 485 c-8 -21 3 -45 20 -45 17 0 31 18 21 28 -3 3 -10 0 -14 -7
-6 -9 -11 -10 -15 -2 -11 16 2 31 21 24 11 -4 14 -3 9 5 -10 17 -36 15 -42 -3z"/>
<path d="M1050 496 c0 -2 6 -16 14 -30 l13 -27 7 23 7 23 9 -23 c13 -31 45
-25 45 8 0 20 -5 25 -25 26 -14 0 -31 -4 -37 -9 -9 -6 -13 -6 -13 2 0 6 -4 11
-10 11 -5 0 -10 -2 -10 -4z"/>
<path d="M1163 475 c1 -19 8 -31 20 -33 13 -3 17 3 16 30 l-1 33 -8 -30 c-7
-29 -8 -29 -24 15 -3 8 -4 2 -3 -15z"/>
<path d="M1234 485 c-4 -8 -4 -22 0 -30 6 -17 36 -21 36 -5 0 6 -6 9 -12 8 -7
-2 -13 4 -13 12 0 8 6 14 13 13 6 -2 12 2 12 7 0 16 -30 12 -36 -5z"/>
<path d="M1284 485 c-15 -37 21 -60 46 -30 12 14 15 14 31 -1 18 -16 19 -16
18 12 0 16 -3 24 -5 17 -4 -8 -12 -8 -32 3 -35 18 -51 18 -58 -1z"/>
<path d="M1391 470 c0 -19 3 -29 6 -22 4 10 8 10 19 1 11 -9 14 -6 14 17 0 20
-3 25 -9 15 -7 -11 -11 -10 -20 5 -9 16 -10 14 -10 -16z"/>
<path d="M1440 470 c0 -25 4 -30 23 -29 19 1 19 2 3 6 -15 4 -16 7 -5 14 12 8
12 10 0 18 -11 7 -11 9 0 14 10 4 9 6 -3 6 -13 1 -18 -7 -18 -29z"/>
<path d="M1491 486 c-6 -7 -9 -21 -5 -30 7 -18 44 -22 44 -6 0 6 -6 10 -12 8
-13 -3 -18 3 -18 23 0 6 7 8 16 5 8 -3 12 -2 9 4 -9 14 -21 12 -34 -4z"/>
<path d="M1580 470 c0 -25 4 -30 23 -29 21 0 21 1 2 9 -11 5 -15 9 -10 9 15 1
13 41 -2 41 -8 0 -13 -12 -13 -30z"/>
<path d="M1630 470 c0 -23 4 -30 20 -30 16 0 20 7 20 30 0 23 -4 30 -20 30
-16 0 -20 -7 -20 -30z"/>
<path d="M1960 491 c-23 -9 -18 -36 8 -39 16 -3 22 2 22 17 0 24 -10 31 -30
22z"/>
<path d="M2037 494 c-4 -4 -7 -16 -7 -26 0 -13 7 -18 26 -18 21 0 25 4 22 22
-3 23 -27 35 -41 22z"/>
<path d="M2110 475 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2192 473 c2 -22 8 -28 28 -28 19 0 24 5 24 25 1 20 -5 26 -27 28
-26 3 -28 1 -25 -25z"/>
<path d="M2272 473 c2 -22 8 -28 28 -28 20 0 26 6 28 28 3 24 0 27 -28 27 -28
0 -31 -3 -28 -27z"/>
<path d="M2360 470 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M1780 470 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
-18 3 -23 -2 -23 -18z"/>
<path d="M1860 470 c0 -15 7 -20 25 -20 18 0 25 5 25 20 0 15 -7 20 -25 20
-18 0 -25 -5 -25 -20z"/>
<path d="M1865 370 c-9 -15 7 -41 24 -38 22 4 21 40 -1 44 -10 1 -20 -1 -23
-6z"/>
<path d="M1950 355 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
-15 0 -20 -7 -20 -25z"/>
<path d="M2030 360 c0 -11 7 -23 15 -27 19 -7 22 -4 27 22 2 14 -3 21 -19 23
-18 3 -23 -2 -23 -18z"/>
<path d="M2112 358 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 18 -1 22 -23 22
-22 0 -26 -4 -23 -22z"/>
<path d="M2192 358 c2 -15 11 -24 26 -26 18 -3 22 1 22 22 0 21 -5 26 -26 26
-21 0 -25 -4 -22 -22z"/>
<path d="M2274 364 c3 -9 6 -20 6 -25 0 -14 17 -11 34 7 24 23 19 34 -15 34
-24 0 -30 -3 -25 -16z"/>
<path d="M1457 363 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33 8z"/>
<path d="M1620 355 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M1702 353 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
-15 0 -20 -5 -18 -17z"/>
<path d="M1784 354 c3 -9 6 -18 6 -20 0 -2 7 -4 15 -4 8 0 15 9 15 20 0 14 -7
20 -21 20 -15 0 -19 -4 -15 -16z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
